<template>
  <div class="login">
    <div class="container login-wrapper">
      <div class="row align-items-center">
        <div class="col-md-5">
          <img src="../assets/login-img.svg" alt="" />
        </div>
        <div class="col-md-7">
          <h3>Login</h3>
          <form @submit.prevent="loginWithOtp">
            <div class="row">
              <div class="col-md-2 col-xs-3">
                <label for="phone_number_code" class="form-lab el">Code</label>
                <select
                  class="form-control"
                  v-model="phone_number_code"
                  id="phone_number_code"
                >
                  <!-- <option value="">Select Code</option> -->
                  <option
                    v-for="(country, countryIndex) in countries"
                    :key="countryIndex"
                    :value="country.phone_code"
                  >
                    {{ country.phone_code }}
                  </option>
                </select>
              </div>
              <div class="col-md-10 col-xs-9">
                <label for="phone" class="form-lab el">Phone</label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  v-model="phone"
                  aria-describedby="phone"
                />
                <div
                  class="input-errors"
                  v-for="(error, errorIndex) of v$.phone.$errors"
                  :key="errorIndex"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
                <div v-if="errors && errors.phone" class="error-msg">
                  {{ errors.phone[0] }}
                </div>
              </div>
            </div>
            <div class="mb-3" v-if="showOtpField == true">
              <label for="exampleInputphone" class="form-lab el">OTP</label>
              <input
                type="text"
                class="form-control"
                id="otp"
                v-model="otp"
                aria-describedby="phoneHelp"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.otp.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="mb-3 action-div">
              <span v-if="this.loginType == 'Nest'"
                >or try with
                <router-link
                  :to="{ name: 'otpEmailLogin', query: { name: 'Nest' } }"
                  >Email</router-link
                ></span
              >
              <span v-else
                >or try with
                <router-link
                  :to="{ name: 'otpEmailLogin', query: { name: 'Pupa' } }"
                  >Email</router-link
                ></span
              >
            </div>
            <div class="text-right row">
              <div class="col">
                <button
                  type="submit"
                  :disabled="isAuthenticating == true"
                  class="btn cta-primary"
                >
                  <span
                    v-if="isAuthenticating == false && showOtpField == false"
                  >
                    Get OTP
                  </span>
                  <span
                    v-else-if="
                      // eslint-disable-next-line prettier/prettier
                      isAuthenticating == false && showOtpField == true"
                  >
                    Login
                  </span>
                  <span v-else>
                    <circular-loader></circular-loader>
                  </span>
                </button>
              </div>
              <div
                class="col"
                v-if="isAuthenticating == false && showOtpField == true"
              >
                <button
                  :disabled="isResending == true"
                  @click="resendOtp()"
                  class="btn cta-primary"
                >
                  <span v-if="isResending == false">
                    Resend OTP
                  </span>
                  <span v-else>
                    <circular-loader></circular-loader>
                  </span>
                </button>
              </div>
            </div>
            <div class="mb-3 action-div">
              <span v-if="this.loginType == 'Nest'"
                >Login with
                <router-link :to="{ name: 'login', query: { name: 'Nest' } }">
                  Password</router-link
                ></span
              >
              <span v-else
                >Login with
                <router-link :to="{ name: 'login', query: { name: 'Pupa' } }">
                  Password</router-link
                ></span
              >
              <span v-if="this.loginType == 'Nest'"
                >Don’t have an account
                <router-link
                  :to="{ name: 'nestRegister', query: { name: 'Nest' } }"
                  >Register</router-link
                ></span
              >
              <span v-else
                >Don’t have an account
                <router-link :to="{ name: 'register', query: { name: 'Pupa' } }"
                  >Register</router-link
                ></span
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeService from "@/services/HomeService";
import useVuelidate from "@vuelidate/core";
import AuthService from "@/services/AuthService";
import CircularLoader from "@/components/loaders/CircularLoader";
import "bootstrap";
import errorLog from "@/errorLog";
import { required, helpers } from "@vuelidate/validators";
export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  name: "OtpPhoneLogin",
  components: {
    CircularLoader
  },
  created() {
    this.getCountryCode();
  },
  data() {
    return {
      authStatus: "",
      token: "",
      user: "",
      phone: "",
      phone_number_code: "+91",
      loginType: this.$route.query.name,
      loginWithOTP: false,
      isAuthenticating: false,
      otp: "",
      countries: [],
      isVerifying: false,
      isResending: false,
      showOtpField: false,
      errors: {}
    };
  },
  validations() {
    return {
      otp: {
        required: helpers.withMessage("Please enter otp", required)
      },
      phone_number_code: {
        required: helpers.withMessage(
          "Please enter your phone number code",
          required
        )
      },
      phone: {
        required: helpers.withMessage(
          "Please enter your phone number",
          required
        )
      }
    };
  },
  methods: {
    loginWithOtp() {
      const data = {
        user_name: this.phone,
        phone_number_code: this.phone_number_code,
        otp: this.otp
      };
      this.v$.phone.$touch();
      if (this.showOtpField == true) {
        this.v$.otp.$touch();
      }
      if (this.v$.phone.$invalid) {
        return;
      }
      if (this.v$.otp.$invalid && this.showOtpField == true) {
        return;
      }
      this.isAuthenticating = true;
      AuthService.getOTP(data)
        .then(result => {
          this.isAuthenticating = false;
          if (result.data.status == "otp_send") {
            this.showOtpField = true;
            this.$toast.success(result.data.message);
          } else if (result.data.status == "login_success") {
            this.$toast.success(result.data.message);
            const userData = {
              token: result.data.token,
              user: result.data.user
            };
            this.$store.dispatch("studentAuth/authSuccess", userData);
            this.isAuthenticating = false;
            if (this.$store.state.studentAuth.nextUrl == "") {
              this.$router.push({
                name: "profile"
              });
            } else {
              this.$router.push(this.$store.state.studentAuth.nextUrl);
              this.$store.dispatch("studentAuth/updateNextUrl", "");
            }
          } else {
            this.$toast.error(result.data.message);
          }
        })
        .catch(error => {
          this.error = error;
          this.isAuthenticating = false;
        });
    },
    async getCountryCode() {
      await HomeService.getCountryCode()
        .then(result => {
          this.countries = result.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    async resendOtp() {
      const data = {
        user_name: this.phone,
        phone_number_code: this.phone_number_code
      };
      this.isResending = true;
      await AuthService.getOTP(data)
        .then(result => {
          if (result.data.status == "otp_send") {
            this.$toast.success(result.data.message);
            this.isResending = false;
          }
        })
        .catch(error => {
          errorLog.log(error);
          this.isResending = false;
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/login.scss";
</style>
